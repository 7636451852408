import Layout from '../../components/Layout/layout.component';
import './contact.styles.css';

export default function Contact() {
  return (
    <Layout>
      <section className='section'>
        <div className='banner contact-banner'>Contact</div>
      </section>

      <div className='contact-container section-content'>
        <h4>表演、合作、邀稿接洽：</h4>
        <br />
        <h4>Facebook粉絲頁私訊</h4>
        <a href='https://www.facebook.com/shimautauta'>《張雅淳》</a>
        <h4>
          <br />
        </h4>
        <h4>Instagram</h4>
        <a href='www.instagram.com/yasi0307'>www.instagram.com/yasi0307</a>
        <h4>
          <br />
        </h4>
        <h4>E-MAIL</h4>
        <a href='mailto:yasi0307@gmail.com'>yasi0307@gmail.com</a>
      </div>
      <div className='section'></div>
    </Layout>
  );
}
