import React, { useEffect } from "react";
import Layout from "../../components/Layout/layout.component";
import Instagram from "../../components/instagram/instagram.component";
import SocialLinks from "../../components/social-links/social-links.component";
import YoutubeVideo from "../../components/youtube/youtube.component";
import hero from "../../img/main-photo.jpeg";

import Aos from "aos";
import "aos/dist/aos.css";

import "./homepage.styles.css";

const HomePage = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <span data-aos="fade-left" className="sub-title section">
            秀藝傳歌｜唸歌｜民謠
          </span>
          <SocialLinks styleClass="herder-links" />
          <div className="section">
            <div className="heroImg-container">
              <img
                data-aos="fade-right"
                src={hero}
                alt="hero"
                className="hero-img"
              />
            </div>
            <span className="photo-credit">Photo Credit: 林山</span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="photo-credit"
              href="https://www.instagram.com/shansansang"
            >
              @shansansang
            </a>
          </div>
          <YoutubeVideo />
          <Instagram />
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
