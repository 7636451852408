import React from "react";

const YoutubeVideo = () => {
  return (
    <>
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0,
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src="https://www.youtube.com/embed/4bpuyEXEQp0"
          frameBorder="0"
          title="老仙婆遊天庭-序章"
        />
      </div>
      <div className="section-content"></div>
    </>
  );
};

export default YoutubeVideo;
