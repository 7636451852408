import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import { VscMail } from 'react-icons/vsc';
import './social-links.styles.css';

const data = [
  {
    id: 1,
    icon: <FaFacebookF className='social-icon'></FaFacebookF>,
    url: 'https://www.facebook.com/shimautauta',
  },
  {
    id: 2,
    icon: <FaInstagram className='social-icon'></FaInstagram>,
    url: 'https://www.instagram.com/yasi0307',
  },
  {
    id: 3,
    icon: <VscMail className='social-icon'></VscMail>,
    url: 'mailto:yasi0307@gmail.com',
  },
  {
    id: 4,
    icon: <FaYoutube className='social-icon'></FaYoutube>,
    url: 'https://www.youtube.com/user/yasi0307',
  },
];
const links = data.map((link) => {
  return (
    <li key={link.id}>
      <a href={link.url} className='social-link'>
        {link.icon}
      </a>
    </li>
  );
});

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ''}`}>{links}</ul>
  );
};
