import React from 'react';
import './header.styles.css';

const Header = () => {
  return (
    <>
      <header className='cento-header'>
        <div className='container'>
          <span className='logo'>
            <a href='/'>張雅淳 TIUNN NGÁ-SÛN</a>
          </span>
        </div>
      </header>
    </>
  );
};

export default Header;
