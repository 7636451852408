import Layout from '../../components/Layout/layout.component';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import './news.styles.css';

export default function News() {
  return (
    <Layout>
      <section className='section'>
        <div className='banner news-banner'>News</div>
      </section>
      <div className='news-container'>
        <div className='section-content content-container'>
          <TwitterTimelineEmbed
            sourceType='profile'
            screenName='TiunnNgaSun'
            options={{ height: 900 }}
          />
        </div>
      </div>
    </Layout>
  );
}
