import React from 'react';
import SocialLinks from '../social-links/social-links.component';
import './footer.styles.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <SocialLinks styleClass='footer-links' />
      <h4>
        &copy; {new Date().getFullYear()} website built by{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          className='bibo'
          href='https://bibochan.com/'
        >
          Bibo Chan
        </a>
      </h4>
    </footer>
  );
};

export default Footer;
