import React from 'react';
import Header from '../header/header.component';
import Navbar from '../navbar/navbar.component';
import Sidebar from '../sidebar/sidebar.component';
import Footer from '../footer/footer.component';
const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Header />
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
