import React, { useState } from "react";
import { MdMenu } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./navbar.styles.css";

const Navbar = ({ toggleSidebar }) => {
  const [navbar, setNavbar] = useState(false);
  const showFixedNavbar = () => {
    if (window.scrollY >= 145) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", showFixedNavbar);

  return (
    <>
      <button type="button" className="toggle-btn" onClick={toggleSidebar}>
        <MdMenu />
      </button>
      <div className={navbar ? "navbar-active navbar" : "navbar"}>
        <div className="nav-wrap">
          <ul className="nav-menu">
            <NavLink
              className={({ isActive }) =>
                "menu-item" + (isActive ? " activated" : "")
              }
              to="/"
            >
              home
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                "menu-item" + (isActive ? " activated" : "")
              }
              to="/about"
            >
              about
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                "menu-item" + (isActive ? " activated" : "")
              }
              to="/music"
            >
              music
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                "menu-item" + (isActive ? " activated" : "")
              }
              to="/news"
            >
              news
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                "menu-item" + (isActive ? " activated" : "")
              }
              to="/contact"
            >
              contact
            </NavLink>
            <a className="menu-item" href="https://vocus.cc/user/@yasi0307">
              vocus 方格子
            </a>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
