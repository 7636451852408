import React from "react";
import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import SocialLinks from "../social-links/social-links.component";
import "./sidebar.styles.css";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={`sidebar ${isOpen ? "show-sidebar" : ""}`}>
      <button className="close-btn" onClick={toggleSidebar}>
        <MdClose />
      </button>

      <div className="side-container">
        <span className="logo">
          <a href="/">張雅淳 TIUNN NGÁ-SÛN</a>
        </span>
        <ul className="sidebar-links">
          <NavLink
            className={({ isActive }) =>
              "sidebar-links" + (isActive ? " activated" : "")
            }
            to="/"
          >
            home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              "sidebar-links" + (isActive ? " activated" : "")
            }
            to="/about"
          >
            about
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              "sidebar-links" + (isActive ? " activated" : "")
            }
            to="/music"
          >
            music
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              "sidebar-links" + (isActive ? " activated" : "")
            }
            to="/news"
          >
            news
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              "sidebar-links" + (isActive ? " activated" : "")
            }
            to="/contact"
          >
            contact
          </NavLink>
          <a className="sidebar-links" href="https://vocus.cc/user/@yasi0307">
            vocus 方格子
          </a>
        </ul>
        <SocialLinks styleClass={`${isOpen ? "sidebar-icons" : ""}`} />
      </div>
    </aside>
  );
};

export default Sidebar;
